<template>
  <div class="container-top bg-black">
    <div class="pc container"  style="padding-top:120px">
      <div class="text-center title-box">
        <div class="size-24 primary">내 사업에 꼭 맞춘 커스텀 플랫폼을 만들고 싶다면?</div>
        <div class="prime-title">Prime</div>
        <div class="body1 gray">완벽함을 위한 VIP 솔루션<br>A to Z, 서비스의 모든 디자인과 기능을 내가 원하는대로</div>
      </div>

      <div class="h8 main padding-top-52">Prime 제작 의뢰 상담 신청</div>
      <div class="h8 main padding-top-44">원하시는 상담 수준을 알려주세요</div>
      <div class="lp-divider-gray1 padding-top-8"></div>
      <select-counsel @update:value="val=>counsel=val.value" style="margin-top:21px"></select-counsel>

      <div class="h8 main padding-top-52">제작하려는 플랫폼 서비스 정보를 알려주세요</div>
      <div class="lp-divider-gray1 padding-top-8"></div>
      <div class="padding-top-16"></div>
      <div v-for="(form, form_idx) in formData" :key="`form-${form_idx}`">
        <div class="margin-top-16">
          <div class="inquiry-reg-title subtitle5">
            {{ form.field!=='checkedVal'? form.label : '' }} {{ form.validate.required? '*':'' }}
          </div>
          <div class="inquiry-reg-content">
            <input-file placeholder="예) 소개서, 기획안 와이어프레임 등등"
                        @setValue="val=>form.value=val"
                        :value.sync="form.file_ext"
                        v-if="form.component_name==='input-single-file'"></input-file>
            <!--<input-single-file :value.sync="form.file_ext"
                               @setValue="val=>form.value=val"
                               placeholder="예) 소개서, 기획안 와이어프레임 등등"
                               v-if="form.component_name==='input-single-file'"></input-single-file>-->
            <component :is="form.component_name"
                       :ref="form.field"
                       v-bind.sync="form" v-else></component>
          </div>
        </div>
        <div v-if="form.field==='support'" class="h8 main padding-top-52">신청하시는 Prime 고객님의 정보를 알려주세요</div>
        <div v-if="form.field==='support'" class="lp-divider-gray1 padding-top-8"></div>
      </div>
      <div class="lp-divider-gray2 margin-top-8"></div>
      <div class="margin-top-80 flex-center">
        <button class="button is-primary" style="width:242px;height:48px" @click="saveData('pc')">제작 의뢰 상담 신청</button>
      </div>
    </div>

    <div class="mobile container">
      <div class="h7 main padding-top-24">주문제작 견적 신청</div>
      <div class="h8 sub margin-top-32">서비스 정보</div>
      <div class="lp-divider-gray2 margin-top-8 margin-bottom-8"></div>

      <div v-for="(form, form_idx) in formData" :key="`form-${form_idx}`">
        <div :class="{'margin-top-12':form.field!=='support','margin-top-24':form.field==='support'}">
          <div class="subtitle7 sub" v-if="form.field !== 'checkedVal'">{{ form.label }}</div>
          <input-file class="margin-top-8"
                      placeholder="예) 소개서, 기획안 와이어프레임 등등"
                      @setValue="val=>form.value=val"
                      :value.sync="form.file_ext"
                      v-if="form.component_name==='input-single-file'"></input-file>
          <!--<input-single-file class="margin-top-8"
                             :value.sync="form.file_ext"
                             @setValue="val=>form.value=val"
                             placeholder="예) 소개서, 기획안 와이어프레임 등등"
                             v-if="form.component_name==='input-single-file'"></input-single-file>-->
          <component :is="form.component_name"
                     class="margin-top-8"
                     v-bind.sync="form" v-else></component>
        </div>
        <div v-if="form.field==='support'" class="h8 main padding-top-48">신청자 정보</div>
        <div v-if="form.field==='support'" class="lp-divider-gray2 margin-top-8 margin-bottom-8"></div>
      </div>

      <!--<div class="flex-align margin-top-24 body6">
        <lp-check-bool :checkedVal.sync="checkedVal"
                       label="개인 정보 수집 및 이용안내 동의"></lp-check-bool>
        <span class="primary">(필수)</span>
        <a class="sub3 text-underline margin-left-8 unselect" href="privacy" target="_blank">약관보기</a>
      </div>-->

      <button class="button is-primary margin-top-40 body2-bold"
              style="width:100%;height:48px;"
              @click="saveData('mobile')"
              v-if="!$route.query.id">신청하기</button>
    </div>

    <div class="success-modal-pc">
      <sweet-modal ref="successModal" overlay-theme="dark" :width="modalWidth" :blocking="true"
                   :enable-mobile-fullscreen="false"
                   @close="$refs.successModal.close()" :hide-close-button="true">
        <div class="modal-container">
          <div class="subtitle4 main margin-top-12">견적신청이 완료되었습니다.</div>
          <div class="flex-between margin-top-64">
            <button class="button body2-bold sub2"
                    style="width:50%;height:52px"
                    @click="$router.replace('/home')">홈으로 가기</button>
            <button class="button is-primary body2-bold margin-left-16"
                    style="width:50%;height:52px"
                    @click="$router.replace(`/service_inquiry_detail?id=${inquiry_id}`)">견적 신청 확인하기</button>
          </div>
        </div>
      </sweet-modal>
    </div>

    <div class="success-modal-mobile">
      <sweet-modal ref="successModalMobile" overlay-theme="dark" :width="modalWidth" :blocking="true"
                   :enable-mobile-fullscreen="false"
                   @close="$refs.successModalMobile.close()" :hide-close-button="true">
        <div class="modal-container">
          <div class="body4 main margin-top-36">견적신청이 완료되었습니다.</div>
          <div class="flex-between margin-top-52">
            <button class="button body5-medium sub2"
                    style="width:50%;height:36px"
                    @click="$router.replace('/home')">홈으로 가기</button>
            <button class="button is-primary body5-medium margin-left-16"
                    style="width:50%;height:36px"
                    @click="$router.replace(`/service_inquiry_detail?id=${inquiry_id}`)">견적 신청 확인하기</button>
          </div>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>
<script>
  import LpCheckBool from "../module/LpCheckBool";
  import LpRadio from "../module/LpRadio";
  import InputSingleFile from "../module/InputSingleFile";
  import LpText from "../component/LpText";
  import LpTextArea from "../component/LpTextArea";
  import LpNumberUnit from "../component/LpNumberUnit";
  import FormMixin from "../../mixins/FormMixin";
  import LpCheckBoxArray from "../component/LpCheckBoxArray";
  import { SweetModal } from 'sweet-modal-vue';
  import InputFile from "../module/InputFile";
  import SelectCounsel from "../component/SelectCounsel";

  export default {
    name: "ServiceInquiryReg",
    components: {
      SelectCounsel,
      InputFile,
      LpTextArea, LpText, InputSingleFile, LpCheckBool, LpRadio, LpNumberUnit, LpCheckBoxArray, SweetModal},
    mixins: [
      FormMixin
    ],
    data() {
      return {
        counsel: '',
        modalWidth: 560,
        formData: [],
        checkedVal: false,
        supportOption: [
          {label: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)', value: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)'},
          {label: 'PC 웹 단독(모바일 접속 시 PC 웹 뷰 제공)', value: 'PC 웹 단독(모바일 접속 시 PC 웹 뷰 제공)'},
          {label: 'PC 웹 + 모바일 웹', value: 'PC 웹 + 모바일 웹'},
          {label: 'PC 웹 + 모바일 웹 + 모바일 앱', value: 'PC 웹 + 모바일 웹 + 모바일 앱'}
        ],
        inquiry_id: 0
      }
    },
    created() {
      if(window.innerWidth<500) {
        this.modalWidth = 'none'
      }
      /*this.$nextTick(()=>{
        this.$refs.successModalMobile.open();
      })*/
      this.setForm();
    },
    watch: {
      enableCheckSignup(n) {
        if(n) {
          let items = [
            {
              label: '비밀번호',
              placeholder: '비밀번호를 입력해주세요.',
              maxLength: 30,
              field: 'password',
              type: 'password',
              value: '',
              component_name: 'lp-text',
              errorMsg: '',
              validate: {
                num: true,
                alpha_g: true,
                alpha_s: true,
                special: true,
                max_length: 30,
                min_length: 1,
                valid_required: true,
                required: true
              },
            },
            {
              label: '비밀번호 확인',
              placeholder: '비밀번호 확인을 입력해주세요.',
              maxLength: 30,
              field: 'password_new',
              type: 'password',
              value: '',
              component_name: 'lp-text',
              errorMsg: '',
              validate: {
                num: true,
                alpha_g: true,
                alpha_s: true,
                special: true,
                max_length: 30,
                min_length: 1,
                valid_required: true,
                required: true
              },
            }
          ]
          this.formData = this.formData.concat(items);
        } else {
          let pass = this.formData.findIndex(item=>{ return item.field === 'password'})
          this.formData.remove(pass);
          let password_new = this.formData.findIndex(item=>{ return item.field === 'password_new'})
          this.formData.remove(password_new);
        }
      }
    },
    computed: {
      // 회원가입 체크 여부
      enableCheckSignup() {
        let enable = false;
        let item = this.findItem(this.formData, 'field', 'check_signup');
        if(item && item.value) {
          enable = true;
        }
        return enable;
      }
    },
    methods: {
      setForm() {
        let checkFontSize = '15px';
        let width = '270px';
        if(window.innerWidth<500) {
          checkFontSize = '12px';
          width = '100%';
        }
        this.formData = [
          {
            label: '서비스 이름',
            placeholder: '서비스 이름을 입력해주세요.',
            maxLength: 30,
            field: 'service_name',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '서비스 소개',
            placeholder: '서비스 소개를 적어주세요.',
            maxLength: 10000,
            field: 'content',
            type: 'text',
            value: '',
            component_name: 'lp-text-area',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 10000,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '제작예산',
            placeholder: "0",
            maxLength: 30,
            field: 'account',
            type: 'number',
            value: 0,
            component_name: 'lp-number-unit',
            unit: '만원',
            width: width,
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 1000000000000,
              min_length: 0,
              valid_required: false
            },
          },
          {
            label: '희망 소요기간',
            placeholder: "0",
            maxLength: 30,
            field: 'hope_date',
            type: 'number',
            value: 0,
            component_name: 'lp-number-unit',
            unit: '개월',
            width: width,
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 1000,
              min_length: 0,
              valid_required: false
            },
          },
          {
            label: '참고자료',
            placeholder: '예) 소개서, 기획안 와이어프레임 등등',
            field: 'files',
            value: '',
            component_name: 'input-single-file',
            file_ext: '',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: false
            },
          },
          {
            label: '서비스 지원환경',
            placeholder: '',
            field: 'support',
            value: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)',
            block: true,
            data: [
              {label: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)', value: '모바일 웹 단독(PC로 접속 시 모바일 웹 뷰 제공)'},
              {label: 'PC 웹 단독(모바일 접속 시 PC 웹 뷰 제공)', value: 'PC 웹 단독(모바일 접속 시 PC 웹 뷰 제공)'},
              {label: 'PC 웹 + 모바일 웹', value: 'PC 웹 + 모바일 웹'},
              {label: 'PC 웹 + 모바일 웹 + 모바일 앱', value: 'PC 웹 + 모바일 웹 + 모바일 앱'}
            ],
            label_name: 'label',
            value_name: 'value',
            component_name: 'lp-radio',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '성함(혹은 회사명)',
            placeholder: '성함을 입력해주세요.',
            maxLength: 30,
            field: 'name',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '연락처',
            placeholder: '연락처를 입력해주세요.',
            maxLength: 30,
            field: 'phone',
            type: 'phone',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '이메일',
            placeholder: '이메일을 입력해주세요.',
            maxLength: 30,
            field: 'email',
            type: 'email',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '이용약관 및 개인정보처리방침에 동의하기',
            items: [
              {
                label: '이용약관 및 개인정보처리방침에 동의하기',
                labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#555'
                }
              },
              {
                label: '(필수)', labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#FF6600'
                }
              },
              {
                label: '약관보기', link: 'term',
                labelStyle: {
                  color: '#828282',
                  fontSize: checkFontSize,
                  textDecoration: 'underline',
                  marginLeft: '8px'
                }
              }
            ],
            field: 'checkedVal',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: true
            },
          },
          {
            label: '개인정보 처리 위탁에 동의',
            items: [
              {
                label: '개인정보 처리 위탁에 동의',
                labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#555'
                }
              },
              {
                label: '(필수)', labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#FF6600'
                }
              },
              {
                label: '약관보기', link: 'privacy',
                labelStyle: {
                  color: '#828282',
                  fontSize: checkFontSize,
                  textDecoration: 'underline',
                  marginLeft: '8px'
                }
              }
            ],
            field: 'checkedVal',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: true
            },
          }
        ];
        let name = '';
        let email = '';
        let phone = '';
        if (this.user.user_id) {
          let user = this.cloneItem(this.$store.getters.user)
          name = user.name;
          email = user.email;
          phone = user.phone;
          this.formData.forEach(item=>{
            if(item.field==='name') {
              item.value = name;
            } else if(item.field==='email') {
              item.value = email;
            } else if(item.field==='phone') {
              item.value = phone;
            }
          })
        } else {
          let item = {
            label: '',
            items: [
              {
                label: '위 정보로 회원가입하여 다음부터 내 Prime 제작 현황에서 확인할 수 있게 합니다',
                labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#555'
                }
              },
              {
                label: '(선택)', labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#FF6600'
                }
              }
            ],
            field: 'check_signup',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: false
            },
          }
          this.formData.push(item);
        }
        // this.getData();
      },
      keyUpPhone() {
        this.value.phone = this.autoHyphenPhone(this.value.phone);
      },
      setNumber(e, max_length) {
        if (e.target.value.length > max_length) {
          this.value.account = parseInt(e.target.value.substr(0, max_length));
        } else if (e.target.value.length === 0) {
          this.value.account = 0;
        } else {
          this.value.account = parseInt(e.target.value);
        }
      },
      getData() {
        let url = `public/launchpack/inquiry/${this.$route.query.id}`;
        this.$axios.get(url).then(res=>{
          this.formData.forEach(item=>{
            item.value = res.data[item.field]
          })
        })
      },
      async saveData(device) {
        let result = this.getParamData(this.formData);
        if(result.enable) {
          if(this.enableCheckSignup && result.params.password !== result.params.password_new) {
            this.toast('비밀번호 확인값이 일치하지 않습니다.')
          } else {
            if(this.enableCheckSignup) {
              let params = {
                username: result.params.email,
                name: result.params.name,
                password: result.params.password,
                phone: result.params.phone,
                email: result.params.email,
              }
              this.$axios.post('auth/user/signup', params).then(res=>{
                if(res.status === 200) {
                  // this.setGa('가입 1단계 완료', '응답', '가입 1단계 완료');
                  // this.$router.push(`signemail?email=${result.params.username}`);
                  this.regInquiry(result, device);
                }
              }).catch(error => {
                if (error.response) {
                  let res = error.response;
                  this.toast(res.data.message);
                }
              })
            } else {
              this.regInquiry(result, device);
            }
          }

        }
      },
      regInquiry(result, device) {
        let params = this.cloneItem(result.params);
        if (this.isLogin) {
          params.user_id = this.user.user_id;
        }
        params.counsel = this.counsel;
        let url = 'public/launchpack/v1/inquiry';
        this.$axios.post(url, params).then(res => {
          if (res.status === 200) {
            this.routeGa('', '주문제작 견적 신청', '등록완료', '응답');
            this.inquiry_id = res.data;
            if(device==='pc') this.$refs.successModal.open();
            else this.$refs.successModalMobile.open();
          }
        }).catch(err => {
          if (err.response.data.message) {
            this.toast(err.response.data.message);
          } else if (err.response.data.detail) {
            this.toast(err.response.data.detail);
          } else {
            this.toast('오류가 발생하였습니다. 관리자에게 문의주세요.');
          }
        });
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .bg-black
    background-color #000000

  .prime-title
    padding 16px 0 20px 0
    font-size 60px
    line-height 84px
    font-weight 700
    color $primary

  .title-box
    background-image url('/static/img/img_prime_back.svg')

  .pc .inquiry-reg-title
    width 30%

  .pc .inquiry-reg-content
    width 70%
    margin-top 8px
  .modal-container
    padding 20px
</style>
<style lang="stylus">
  .success-modal-mobile .sweet-modal.is-visible
    height 184px

  .success-modal-mobile .sweet-modal.is-alert .sweet-content
    padding-top 0
    padding-bottom 0
</style>
