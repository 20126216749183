<template>
  <div>
    <div class="card-wrapper">
      <div class="card-info unselect"
           :class="{'selected-card': inputValue && item.value === inputValue.value}"
           v-for="item in list"
           :key="`hosting-${item.value}`"
           @click="selectItem(item)">
        <div class="flex-align">
          <i class="material-icons gray" :class="{'selected':inputValue && item.value === inputValue.value}">
            {{ inputValue && item.value === inputValue.value ? 'radio_button_checked':'radio_button_unchecked' }}</i>
          <div class="body2 margin-left-8">{{ item.name }}</div>
        </div>
        <div class="margin-top-4">
          <div v-if="item.price>0">
            <span class="h6 main">{{ item.price | currencyNum }}</span>
            <span class="body4 main margin-left-2">원</span>
            <span class="body4 main">/</span>
            <span class="body4 main">{{ item.type }}</span>
          </div>
          <span class="h6 main" v-else>무료</span>
        </div>
        <div class="body2 sub3 padding-top-12">
          <div class="body4">{{ item.desc }}</div>
          <div class="body4">{{ item.name==='BASIC'? '최초 개설 시 추천':'' }}</div>
        </div>

        <!--<div class="margin-top-4">
          <div v-if="item.price_type===0">
            <span class="h6 main">{{ item.price | currencyNum }}</span>
            <span class="body4 main margin-left-2">원</span>
            <span class="body4 sub3">/</span>
            <span class="body4 sub3 margin-left-4">월</span>
          </div>
          <div class="h7 main" v-else>{{ item.price_text }}</div>
        </div>-->

      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SelectCounsel",
    props: {
      value: {
        type: Object
      }
    },
    created() {
      this.getData();
    },
    data() {
      return {
        list: [
          { name: '기본', value: '기본', price: 0, desc: 'Prime 서비스 가능여부 확인 Step2 진행 결정 시 미팅 진행'},
          { name: '비즈니스 전문가 검토', value: '비즈니스 전문가 검토', price: 200000, type: '건', desc: 'MVP 모델링 전문가, 투자사, 변리사로 이루어진 전문가그룹의 온라인 검토 후 의견 전달'},
          { name: '오프라인 상담', value: '오프라인 상담', price: 100000, type: '1시간', desc: '구상하는 아이디어를 실현하기 위해 최적의 방법론 상담, 예산 대비 효과를 위한 견적 컨설팅'}
        ],
        inputValue: undefined
      }
    },
    methods: {
      selectItem(item) {
        this.inputValue = item;
        this.$emit('update:value', this.inputValue);
      },
      getData() {
        this.inputValue = this.list[0];
        this.$emit('update:value', this.list[0]);
      },
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .card-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(3, 1fr)
    .card-info
      border 1px solid $gray1
      padding 32px
      border-radius 8px

  .mobile
    .card-info
      border 1px solid $gray1
      padding 24px
      border-radius 8px
      margin-top 24px

  .introduction-content
    white-space pre-line

  .selected
    color $primary

  .selected-card
    border 2px solid $primary !important

</style>
